import { message } from 'ant-design-vue';
import axios from 'axios'
import QueryString from 'qs';
import config from './config.js';
import router from '@/router/index.ts';
axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? config.host : '';

// 中间件配置
axios.interceptors.request.use(config => {
  let token = window.sessionStorage.getItem('token');
  if (token) {
    config.headers.token = token;
  }
  config.headers = {
    ...config.headers, 'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    dataType: "json",
  }
  try {
    if (config.method == 'post') {
      let data = QueryString.parse({
        ...config.data,
      });
      config.data = data
    }
    else if (config.method == 'get') {
      config.params = {
        ...config.params,
      }
    }
  } catch (err) {
    console.log(err);
  }
  return config
}, error => {

  return error
})

// 中间件监听
axios.interceptors.response.use(response => {
  // console.log(response);
  let status = response.status;
  if (status >= 200 && status < 300 || status === 304) {
    if (response.data.code != 1) {
      if (response.data.code == '10003') {
        // window.location.href = '/'
        message.error('未登录, 请登录后重试!');
        setTimeout(() => {
          router.replace({
            path: '/prev/preview'
          })
        }, 1000)
      }
      else {
        message.error(response.data.msg);
      }
    }
  } else {
    message.error('网络错误');
  }
  return response.data;
}, error => {
  // console.log(error);
  message.error('网络错误');
  return error;
})


export default {
  // 整理用户管理
  get_zl_lists(params) {
    return axios.get('/index.php/home/user/get_zl_lists', {
      params
    });
  },
  add_zl_user(params) {
    return axios.get('/index.php/home/user/add_zl_user', {
      params
    });
  },
  edit_zl_user(params) {
    return axios.get('/index.php/home/user/edit_zl_user', {
      params
    });
  },
  delete_zl_user(params) {
    return axios.get('/index.php/home/user/delete_zl_user', {
      params
    });
  },
  // 财务类别
  get_financial_type_lists(params) {
    return axios.get('/index.php/home/setting/get_financial_type_lists', {
      params
    });
  },
  add_financial_type(params) {
    return axios.get('/index.php/home/setting/add_financial_type', {
      params
    });
  },
  get_financial_type_info(params) {
    return axios.get('/index.php/home/setting/get_financial_type_info', {
      params
    });
  },
  edit_financial_type(params) {
    return axios.get('/index.php/home/setting/edit_financial_type', {
      params
    });
  },
  get_article_financial(params) {
    return axios.get('/index.php/home/article/get_article_financial', {
      params
    });
  },
  set_article_financial(params) {
    return axios.get('/index.php/home/article/set_article_financial', {
      params
    });
  },

  get_company_financial(params) {
    return axios.get('/index.php/home/company/get_company_financial', {
      params
    });
  },
  set_company_financial(params) {
    return axios.get('/index.php/home/company/set_company_financial', {
      params
    });
  },

  // 提交摘要
  submit_article_info(params) {
    return axios.get('/index.php/home/article/submit_article_info', {
      params
    });
  },
  get_indicator_tag(params) {
    return axios.get('/index.php/home/article/get_indicator_tag', {
      params
    });
  },
  // 申报项目
  get_declare_lists(params) {
    return axios.get('/index.php/home/declare_item/get_lists', {
      params
    });
  },
  delete_declare_item(params) {
    return axios.get('/index.php/home/declare_item/delete_declare_item', {
      params
    });
  },
  add_declare_item(params) {
    return axios.get('/index.php/home/declare_item/add_declare_item', {
      params
    });
  },
  get_declare_item_info(params) {
    return axios.get('/index.php/home/declare_item/get_declare_item_info', {
      params
    });
  },
  edit_declare_item(params) {
    return axios.get('/index.php/home/declare_item/edit_declare_item', {
      params
    });
  },
  set_declare_sort_num(params) {
    return axios.get('/index.php/home/declare_item/set_sort_num', {
      params
    });
  },
  // 公示管理
  get_disposal_lists(params) {
    return axios.get('/index.php/home/article/get_disposal_lists', {
      params
    });
  },
  // 政策日志
  article_log(params) {
    return axios.get('/index.php/home/article/log', {
      params
    });
  },
  get_article_by_logid(params) {
    return axios.get('/index.php/home/article/get_article_by_logid', {
      params
    });
  },
  article_get(params) {
    return axios.get('/index.php/home/article/get', {
      params
    });
  },
  // 匹配政策数据
  match_article(params) {
    return axios.get('/index.php/home/article/match_article', {
      params
    });
  },
  get_match_article_lists(params) {
    return axios.get('/index.php/home/article/get_match_article_lists', {
      params
    });
  },

  get_match_detail(params) {
    return axios.get('/index.php/home/article/get_match_detail', {
      params
    });
  },
  
  get_zcts_lists(params) {
    return axios.get('/index.php/home/article/get_zcts_lists', {
      params
    });
  },
  get_gzt_num(params) {
    return axios.get('/index.php/home/article/get_gzt_num', {
      params
    });
  },
  get_match_company_lists(params) {
    return axios.get('/index.php/home/article/get_match_company_lists', {
      params
    });
  },
  match_company(params) {
    return axios.get('/index.php/home/article/match_company', {
      params
    });
  },
  get_match_lists(params) {
    return axios.get('/index.php/home/article/get_match_lists', {
      params
    });
  },
  // 项目类别
  get_item_lists(params) {
    return axios.get('/index.php/home/item/get_lists', {
      params
    });
  },
  add_item(params) {
    return axios.get('/index.php/home/item/add_item', {
      params
    });
  },
  get_item_info(params) {
    return axios.get('/index.php/home/item/get_item_info', {
      params
    });
  },
  edit_item(params) {
    return axios.get('/index.php/home/item/edit_item', {
      params
    });
  },
  set_item_sort_num(params) {
    return axios.get('/index.php/home/item/set_sort_num', {
      params
    });
  },
  // 产业类别
  get_trade_lists(params) {
    return axios.get('/index.php/home/trade/get_lists', {
      params
    });
  },
  add_trade(params) {
    return axios.get('/index.php/home/trade/add_trade', {
      params
    });
  },
  del_trade(params) {
    return axios.get('/index.php/home/trade/delete_trade', {
      params
    });
  },
  get_trade_info(params) {
    return axios.get('/index.php/home/trade/get_trade_info', {
      params
    });
  },
  edit_trade(params) {
    return axios.get('/index.php/home/trade/edit_trade', {
      params
    });
  },
  set_trade_sort_num(params) {
    return axios.get('/index.php/home/trade/set_sort_num', {
      params
    });
  },
  // 企业信息
  get_company_lists(params) {
    return axios.get('/index.php/home/company/get_lists', {
      params
    });
  },
  get_company_tag(params) {
    return axios.get('/index.php/home/company//get_company_tag', {
      params
    });
  },
  add_company(params) {
    return axios.post('/index.php/home/company/add', {
      ...params
    });
  },
  edit_company(params) {
    return axios.post('/index.php/home/company/edit', {
      ...params
    });
  },
  // 拉取数据
  get_search_condition(params) {
    return axios.get('/index.php/home/article/get_search_condition', {
      params
    });
  },
  get_article_lists_work(params) {
    return axios.get('/index.php/home/article/get_article_lists', {
      params
    });
  },
  // 标注数据
  get_article_lists(params) {
    return axios.get('/index.php/home/article/get_lists', {
      params
    });
  },
  get_article_tag(params) {
    return axios.get('/index.php/home/article/get_article_tag', {
      params
    });
  },
  // 数据维护
  set_article_tag(params) {
    return axios.post('/index.php/home/article/set_article_tag', {
      ...params
    });
  },
  delete_article(params) {
    return axios.get('/index.php/home/article/delete_article', {
      params
    });
  },
  delete_article_real(params) {
    return axios.get('/index.php/home/article/delete_article_real', {
      params
    });
  },
  release_article(params) {
    return axios.get('/index.php/home/article/release_article', {
      params
    });
  },
  // 标签管理
  get_input_tag(params) {
    return axios.get('/index.php/home/tag/get_input_tag', {
      params
    });
  },
  get_tag_lists(params) {
    return axios.get('/index.php/home/tag/get_lists', {
      params
    });
  },
  add_tag(params) {
    return axios.post('/index.php/home/tag/add_tag', {
      ...params
    });
  },
  get_tag_info(params) {
    return axios.get('/index.php/home/tag/get_tag_info', {
      params
    });
  },
  edit_tag(params) {
    return axios.get('/index.php/home/tag/edit_tag', {
      params
    });
  },
  delete_tag(params) {
    return axios.get('/index.php/home/tag/delete_tag', {
      params
    });
  },
  set_tag_sort_num(params) {
    return axios.get('/index.php/home/tag/set_sort_num', {
      params
    });
  },
  // 行业管理
  get_industry_lists(params) {
    return axios.get('/index.php/home/industry/get_lists', {
      params
    });
  },
  add_industry(params) {
    return axios.get('/index.php/home/industry/add_industry', {
      params
    });
  },
  get_industry_info(params) {
    return axios.get('/index.php/home/industry/get_industry_info', {
      params
    });
  },
  edit_industry(params) {
    return axios.get('/index.php/home/industry/edit_industry', {
      params
    });
  },
  del_industry(params) {
    return axios.get('/index.php/home/industry/delete_industry', {
      params
    });
  },

  // 企业类型管理
  get_ctype_lists(params) {
    return axios.get('/index.php/home/ctype/get_lists', {
      params
    });
  },
  add_ctype(params) {
    return axios.get('/index.php/home/ctype/add_ctype', {
      params
    });
  },
  get_ctype_info(params) {
    return axios.get('/index.php/home/ctype/get_ctype_info', {
      params
    });
  },
  edit_ctype(params) {
    return axios.get('/index.php/home/ctype/edit_ctype', {
      params
    });
  },
  del_ctype(params) {
    return axios.get('/index.php/home/ctype/delete_ctype', {
      params
    });
  },

  // 分类管理
  get_type_lists(params) {
    return axios.get('/index.php/home/category/get_lists', {
      params
    });
  },
  add_category(params) {
    return axios.get('/index.php/home/category/add_category', {
      params
    });
  },
  get_category_info(params) {
    return axios.get('/index.php/home/category/get_category_info', {
      params
    });
  },
  edit_category(params) {
    return axios.get('/index.php/home/category/edit_category', {
      params
    });
  },
  set_category_sort_num(params) {
    return axios.get('/index.php/home/category/set_sort_num', {
      params
    });
  },

  // 重置密码
  reset_pwd(params) {
    return axios.get('/index.php/home/user/reset_pwd', {
      params
    });
  },

  // 角色管理
  get_role_lists(params) {
    return axios.get('/index.php/home/role/get_lists', {
      params
    });
  },
  add_role(params) {
    return axios.get('/index.php/home/role/add_role', {
      params
    });
  },
  get_role_info(params) {
    return axios.get('/index.php/home/role/get_role_info', {
      params
    });
  },
  edit_role(params) {
    return axios.get('/index.php/home/role/edit_role', {
      params
    });
  },
  delete_role(params) {
    return axios.get('/index.php/home/role/delete_role', {
      params
    });
  },
  set_role_menu(params) {
    return axios.get('/index.php/home/role/set_role_menu', {
      params
    });
  },
  get_role_menu(params) {
    return axios.get('/index.php/home/role/get_role_menu', {
      params
    });
  },
  // 用户管理
  get_user_lists(params) {
    return axios.get('/index.php/home/user/get_lists', {
      params
    });
  },

  // 整理用户管理
  get_arrange_user_lists(params) {
    return axios.get('/index.php/home/user/get_zl_lists', {
      params
    });
  },
  // 所有用户
  get_all_lists(params) {
    return axios.get('/index.php/home/user/get_all_lists', {
      params
    });
  },
  user_lock(params) {
    return axios.get('/index.php/home/user/lock', {
      params
    });
  },
  user_logout(params) {
    return axios.get('/index.php/home/user/logout', {
      params
    });
  },
  add_user(params) {
    return axios.get('/index.php/home/user/add_user', {
      params
    });
  },
  get_user_info(params) {
    return axios.get('/index.php/home/user/get_user_info', {
      params
    });
  },
  edit_user(params) {
    return axios.get('/index.php/home/user/edit_user', {
      params
    });
  },
  // 部门管理
  get_dept_lists(params) {
    return axios.get('/index.php/home/dept/get_lists', {
      params
    });
  },
  add_dept(params) {
    return axios.get('/index.php/home/dept/add_dept', {
      params
    });
  },
  get_dept_info(params) {
    return axios.get('/index.php/home/dept/get_dept_info', {
      params
    });
  },
  edit_dept(params) {
    return axios.get('/index.php/home/dept/edit_dept', {
      params
    });
  },
  delete_dept(params) {
    return axios.get('/index.php/home/dept/delete_dept', {
      params
    });
  },
  // 登录注册
  register(params) {
    return axios.get('/index.php/home/login/register', {
      params
    });
  },
  login(params) {
    return axios.get('/index.php/home/login/login', {
      params
    });
  },
  info(params) {
    return axios.get('/index.php/home/user/info', {
      params
    });
  },
  ///扫码登录使用该方法进行处理
  get_qrcode_user(params) {
    return axios.get('/index.php/home/login/get_qrcode_user', {
      params
    });
  },
  get_setting_lists(params) {
    return axios.get('/index.php/home/setting/get_lists', {
      params
    });
  },
  add_setting(params) {
    return axios.post('/index.php/home/setting/add_setting', {
      ...params
    });
  },
  edit_setting(params) {
    return axios.post('/index.php/home/setting/edit_setting', {
      ...params
    });
  },
  delete_setting(params) {
    return axios.get('/index.php/home/setting/delete_setting', {
      params
    });
  },
  get_option_lists(params) {
    return axios.post('/index.php/home/setting/get_option_lists', {
      ...params
    });
  },
  add_setting_option(params) {
    return axios.get('/index.php/home/setting/add_setting_option', {
      params
    });
  },
  edit_setting_option(params) {
    return axios.get('/index.php/home/setting/edit_setting_option', {
      params
    });
  },
  delete_setting_option(params) {
    return axios.get('/index.php/home/setting/delete_setting_option', {
      params
    });
  },
  get_setting_info(params) {
    return axios.get('/index.php/home/setting/get_setting_info', {
      params
    });
  },
  get_area(params) {
    return axios.get('/index.php/home/index/get_area', {
      params
    });
  },
  get_menu_lists(params) {
    return axios.get('/index.php/home/menu/get_lists', {
      params
    });
  },
  get_my_menu(params) {
    return axios.get('/index.php/home/menu/get_my_menu', {
      params
    });
  },
  add_menu(params) {
    return axios.get('/index.php/home/menu/add_menu', {
      params
    });
  },
  edit_menu(params) {
    return axios.get('/index.php/home/menu/edit_menu', {
      params
    });
  },
  delete_menu(params) {
    return axios.get('/index.php/home/menu/delete_menu', {
      params
    });
  },
  get_token(params) {
    return axios.get('/index.php/home/login/get_token', {
      params
    });
  },
  //无相增加
  get_payment_lists(params) {
    return axios.get('/index.php/home/setting/get_payment_lists', {
      params
    });
  },

  edit_payment(params) {
    return axios.get('/index.php/home/setting/edit_payment', {
      params
    });
  },

  delete_payment(params) {
    return axios.get('/index.php/home/setting/delete_payment', {
      params
    });
  },

  //订单管理
  get_order_lists(params) {
    return axios.get('/index.php/home/setting/get_order_lists', {
      params
    });
  },

  //先票后款订单管理
  get_order_invoice(params) {
    return axios.get('/index.php/home/setting/get_order_invoice', {
      params
    });
  },
  //确认收款
  get_money(params) {
    return axios.get('/index.php/home/setting/get_money', {
      params
    });
  },

  edit_order(params) {
    return axios.get('/index.php/home/setting/edit_order', {
      params
    });
  },

  get_channel_lists(params) {
    return axios.get('/index.php/home/setting/get_channel_lists', {
      params
    });
  },
  add_channel(params) {
    return axios.get('/index.php/home/setting/add_channel', {
      params
    });
  },
  edit_channel(params) {
    return axios.get('/index.php/home/setting/edit_channel', {
      params
    });
  },

  delete_channel(params) {
    return axios.get('/index.php/home/setting/delete_channel', {
      params
    });
  },

  get_package_lists(params) {
    return axios.get('/index.php/home/setting/get_package_lists', {
      params
    });
  },
  add_package(params) {
    return axios.get('/index.php/home/setting/add_package', {
      params
    });
  },
  edit_package(params) {
    return axios.get('/index.php/home/setting/edit_package', {
      params
    });
  },

  delete_package(params) {
    return axios.get('/index.php/home/setting/delete_package', {
      params
    });
  },

  get_buy_qrcode(params) {
    return axios.get('/index.php/home/pay/get_buy_qrcode', {
      params
    });
  },

  get_pay_result(params) {
    return axios.get('/index.php/home/pay/get_pay_result', {
      params
    });
  },

  get_self_order(params) {
    return axios.get('/index.php/home/pay/get_self_order', {
      params
    });
  },

  get_integral_list(params) {
    return axios.get('/index.php/home/pay/get_integral_list', {
      params
    });
  },

  get_subaccount_list(params) {
    return axios.get('/index.php/home/pay/get_subaccount_list', {
      params
    });
  },

  delete_subaccount(params) {
    return axios.get('/index.php/home/pay/delete_subaccount', {
      params
    });
  },

  add_subaccount(params) {
    return axios.get('/index.php/home/pay/add_subaccount', {
      params
    });
  },


  get_salesman_lists(params) {
    return axios.get('/index.php/home/setting/get_salesman_lists', {
      params
    });
  },

  delete_salesman(params) {
    return axios.get('/index.php/home/setting/delete_salesman', {
      params
    });
  },

  add_salesman(params) {
    return axios.get('/index.php/home/setting/add_salesman', {
      params
    });
  },
  edit_salesman(params) {
    return axios.get('/index.php/home/setting/edit_salesman', {
      params
    });
  },

  get_bussiness_lists(params) {
    return axios.get('/index.php/home/pay/get_bussiness_lists', {
      params
    });
  },
  get_bussiness_amount(params) {
    return axios.get('/index.php/home/pay/get_bussiness_amount', {
      params
    });
  },

  setMessage(params) {
    return axios.get('/index.php/home/pay/set_message', {
      params
    });
  },
  setWx(params) {
    return axios.get('/index.php/home/pay/set_wx', {
      params
    });
  },
  verificate_phone(params) {
    return axios.get('/index.php/home/pay/verificate_phone', {
      params
    });
  },
  add_pushaccount(params) {
    return axios.get('/index.php/home/pay/add_pushaccount', {
      params
    });
  },

  delete_pushaccount(params) {
    return axios.get('/index.php/home/pay/delete_pushaccount', {
      params
    });
  },
  get_pushaccount_list(params) {
    return axios.get('/index.php/home/pay/get_pushaccount_list', {
      params
    });
  },


  get_banner_list(params) {
    return axios.get('/index.php/home/setting/get_banner_list', {
      params
    });
  },
  delete_banner(params) {
    return axios.get('/index.php/home/setting/delete_banner', {
      params
    });
  },

  add_banner(params) {
    return axios.get('/index.php/home/setting/add_banner', {
      params
    });
  },
  edit_banner(params) {
    return axios.get('/index.php/home/setting/edit_banner', {
      params
    });
  },
  
  get_article_detail(params){
    return axios.get('/index.php/home/article/get_article_detail', {
      params
    });
  },

  add_article(params){
    return axios.get('/index.php/home/article/add_article', {
      params
    });
  },

  get_years(){
    return axios.get('/index.php/home/article/get_years');
  },

  get_agreement(){
    return axios.get('/index.php/home/index/get_agreement');
  },


  uploadFile: (process.env.NODE_ENV == 'production' ? config.host : '') + '/index.php/api/upload/upload',
  // 下载文件, 附件, 跨域文件使用其他方法
  downloadFile(url, fileName) {
    const x = new XMLHttpRequest();
    x.open('GET', url, true);
    x.responseType = 'blob';
    x.onload = function () {
      const url = window.URL.createObjectURL(x.response);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = fileName;
      a.click();
      a.remove();
    };
    x.send();
  }
}
