import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

// 路由分为: 游客, 后台, 以及前台
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/prev/preview',
  },
  {
    path: '/login-admin',
    name: 'LoginAdmin',
    component: () => import('../views/login/LoginAdmin.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/prev',
    name: 'Prev',
    component: () => import('../layout/Prev.vue'),
    children: [
      {
        path: 'preview',
        name: 'Preview',
        component: () => import('../views/work/Preview.vue')
      },
      {
        path: 'policy',
        name: 'PolicyList2',
        component: () => import('../views/work/policy/List.vue')
      },
      {
        path: 'policyDetail',
        name: 'PolicyDetail',
        component: () => import('../views/work/policy/Detail.vue')
      },
    ]
  },
  {
    path: '/work',
    name: 'Work',
    component: () => import('../layout/Work.vue'),
    children: [
      {
        path: 'home',
        name: 'WorkHome',
        component: () => import('../views/work/Home.vue'),
        children: [
          {
            path: 'index',
            name: 'index',
            component: () => import('../views/work/index/Index.vue')
          },
          {
            path: 'company',
            name: 'CompanyList',
            component: () => import('../views/work/company/List.vue')
          },
          {
            path: 'company-match',
            name: 'CompanyMatch',
            component: () => import('../views/work/company/CompanyMatch.vue')
          },
          {
            path: 'company-push',
            name: 'CompanyPush',
            component: () => import('../views/work/company/CompanyPush.vue')
          },
          {
            path: 'buy-vip',
            name: 'Buyvip',
            component: () => import('../views/work/buyvip/Buyvip.vue')
          },{
            path: 'member',
            name: 'Member',
            component: () => import('../views/work/member/Member.vue')
          },
        ],redirect:"/work/home/index"
      },
    ]
  },
  {
    path: '/platform',
    name: 'Platform',
    component: () => import('../layout/Platform.vue'),
    children: [
      {
        path: 'wecome',
        name: 'Wecome',
        component: () => import('../views/login/Wecome.vue')
      },

      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/cloud/Home.vue'),
        children: [
          {
            path: 'article-log',
            name: 'ArticleLog',
            component: () => import('../views/cloud/article/Log.vue')
          },
          {
            path: 'industry-manage',
            name: 'IndustryManage',
            component: () => import('../views/cloud/industry/List.vue')
          },
          {
            path: 'financial-type',
            name: 'FinancialType',
            component: () => import('../views/cloud/financialType/List.vue')
          },
          {
            path: 'article-gs',
            name: 'ArticleGs',
            component: () => import('../views/cloud/article/ListGsManage.vue')
          },
          {
            path: 'type-manage',
            name: 'TypeManage',
            component: () => import('../views/cloud/type/List.vue')
          },
          {
            path: 'type-study',
            name: 'TypeStudy',
            component: () => import('../views/cloud/studyType/List.vue')
          },
          {
            path: 'type-company',
            name: 'TypeCompany',
            component: () => import('../views/cloud/companyType/List.vue')
          },
          {
            path: 'type-industry',
            name: 'TypeIndustry',
            component: () => import('../views/cloud/industryType/List.vue')
          },
          {
            path: 'article-match',
            name: 'ArticleMath',
            component: () => import('../views/cloud/article/ListPp.vue')
          },
          {
            path: 'article-manage',
            name: 'ArticleManage',
            component: () => import('../views/cloud/article/List.vue')
          },
          {
            path: 'article-recycle',
            name: 'ArticleRecycle',
            component: () => import('../views/cloud/article/Recycle.vue')
          },
          {
            path: 'article-set',
            name: 'ArticleSet',
            component: () => import('../views/cloud/article/ListSet.vue')
          },
          {
            path: 'base-data',
            name: 'BaseData',
            component: () => import('../views/cloud/BaseData.vue')
          },
          {
            path: 'role-manage',
            name: 'RoleMange',
            component: () => import('../views/cloud/role/List.vue')
          },
          {
            path: 'label-manage',
            name: 'LabelMange',
            component: () => import('../views/cloud/label/List.vue')
          },
          {
            path: 'dept-manage',
            name: 'DeptMange',
            component: () => import('../views/cloud/dept/List.vue')
          },
          {
            path: 'menu-manage',
            name: 'MenuMange',
            component: () => import('../views/cloud/menu/List.vue')
          },
          {
            path: 'user-manage',
            name: 'UserMange',
            component: () => import('../views/cloud/user/List.vue')
          },
          {
            path: 'user-admin-manage',
            name: 'UserAdminMange',
            component: () => import('../views/cloud/userAdmin/List.vue')
          },
          {
            path: 'payment-setting',
            name: 'paymentSetting',
            component: () => import('../views/cloud/paymentSetting/List.vue')
          },
          {
            path: 'package-setting',
            name: 'packageSetting',
            component: () => import('../views/cloud/packageSetting/List.vue')
          },
          {
            path: 'channel-setting',
            name: 'channelSetting',
            component: () => import('../views/cloud/channelSetting/List.vue')
          },
          {
            path: 'order-list',
            name: 'orderList',
            component: () => import('../views/cloud/order/List.vue')
          },
          {
            path: 'order-invoice',
            name: 'orderInvoice',
            component: () => import('../views/cloud/orderInvoice/List.vue')
          },
          {
            path: 'salesman',
            name: 'salesMan',
            component: () => import('../views/cloud/salesman/List.vue') 
          },
          {
            path: 'bussiness',
            name: 'bussiness',
            component: () => import('../views/cloud/bussiness/List.vue') 
          },
          {
            path: 'bussiness-amount',
            name: 'bussinessAmount',
            component: () => import('../views/cloud/bussiness/Listamount.vue') 
          },
          {
            path: 'banner',
            name: 'banner',
            component: () => import('../views/cloud/banner/List.vue') 
          },
        ]
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
