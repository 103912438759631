import { createStore } from 'vuex'
import service from '../service/service';

export default createStore({
  state: {
    userinfo: null,
    linkActive: '',
    homenum:null,
    logoUrl:'',
  },
  mutations: {
    setLogoUrl(state, payload) {
      state.logoUrl = payload;
    },
    setUserinfo(state, payload) {
      state.userinfo = payload[1];
    },
    setLinkActive(state, payload) {
      state.linkActive = payload;
    },
    setHomenum(state, payload) {
      state.homenum = payload;
    }
  },
  actions: {
    async getUserinfo({ commit }) {
      const { data } = await service.info();
      // console.log(data);
      if (data) {
        commit('setUserinfo', ['userinfo', data]);
      }
      return data || {};
    },
   
  },
  modules: {
  }
})
