<template>
  <a-config-provider :getPopupContainer="getPopupContainer" :locale="localZh">
    <router-view />
  </a-config-provider>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import 'dayjs/locale/zh-cn';
export default {
  computed: {
    ...mapState(['userinfo']),
    localZh() {
      return zhCN;
    }
  },
  mounted() {
    if (window.sessionStorage.getItem('token')) {
      if (!this.userinfo) this.getUserinfo().then(() => {
        if (process.env.NODE_ENV == 'development') {
          console.log('已登录')
        }
        else {
          // this.$router.push('/platform/home')
        }
      });
    }
  },
  methods: {
    ...mapActions(['getUserinfo']),
    getPopupContainer(el, dialogContext) {
      if (dialogContext) {
        return dialogContext.getDialogWrap();
      } else {
        return document.body;
      }
    },
  }
}
</script>
<style lang="less">
html {
  body {
    background: #f4f5fd;
    // background:@tip-color;
  }
}
</style>
